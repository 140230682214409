/* You can add global styles to this file, and also import other style files */
.width-4px
{
	width: 4px;
}
.width-32px
{
	width: 32px;
}
.width-40px
{
	width: 40px;
}
.width-100
{
	width:100%;
}
.width-300px
{
	width:300px;
}
.height-25px
{
	height:25px;
}
.height-32px
{
	height:32px;
}
.height-40px
{
	height:40px;
}
.height-50px
{
	height:50px;
}
.height-75px
{
	height:75px;
}
.height-100px
{
	height:10px;
}
.min-height-100
{
	min-height: 100%;
}
.min-height-100px
{
	min-height: 100px;
}
.min-height-150px
{
	min-height: 150px;
}
.min-width-25px
{
	min-width: 25px;
}
.min-width-50px
{
	min-width: 50px;
}
.min-width-75px
{
	min-width: 75px;
}
.min-width-100px
{
	min-width: 100px;
}
.min-width-125px
{
	min-width: 125px;
}
.min-width-150px
{
	min-width: 150px;
}
.min-width-200px
{
	min-width: 200px;
}
.min-width-500px
{
	min-width: 500px;
}
.border-2px
{
	border-left-width: 2px!important;
	border-right-width: 2px!important;
	border-top-width: 2px!important;
	border-bottom-width: 2px!important;
}
.pricelist
{
    width: 200px;
}

.errorMessage
{
    color: #FF0000;
}
.text-lightgrey
{
	color:rgb(150, 150, 150);
}
.text-grey
{
	color:rgb(100, 100, 100);
}
.text-darkgrey
{
	color:rgb(50, 50, 50);
}
.text-weight-300
{
	font-weight: 300;
}
.text-bold
{
	font-weight: bold;
}
.text-italic
{
	font-style: italic;
}
.text-underline
{
	text-decoration: underline;
}
.text-strikeout
{
	text-decoration:line-through;
}
.text-align-left
{
	text-align: left;
}
.text-size-12px
{
	font-size: 12px;
}
.text-size-14px
{
	font-size: 14px;
}
.text-size-18px
{
	font-size: 18px;
}
.text-size-20px
{
	font-size: 20px;
}
.text-size-22px
{
	font-size: 22px;
}
.display-inline-block
{
	display: inline-block;
}
.flex-wrap-unset
{
	flex-wrap: unset;
}
.background-black
{
	background:black;
}
.background-white
{
	background:white;
}
.background-whitesmoke
{
	background:whitesmoke;
}
.background-transparent
{
	background:transparent;
}
.overflow-y-auto
{
	overflow-y:auto;
}
.img-center-align
{
	height: 245px;
	max-height: 100%;
	max-width: 100%;
	top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: relative;
}
.img-size-16px
{
	max-width: 16px;
	max-height: 16px;
}
.img-size-24px
{
	max-width: 24px;
	max-height: 24px;
}
.img-size-32px
{
	max-width: 32px;
	max-height: 32px;
}
.img-size-40px
{
	max-width: 40px;
	max-height: 40px;
}
.flip-x
{
	transform: scaleX(-1);
}
.vl
{
	border-left: 6px solid rgb(212, 212, 212);
	height: 100%;
}
.vertical-center
 {
	margin: 0;
	position: relative;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
  }
.google-button:hover
{
    background-color: #fff;
}
.google-button .icon {
    border-radius: 1px;
	vertical-align: middle;
	height: 25px;
	margin-right: 10px;
}

.google-button .label {
    display: inline;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto',sans-serif;
    color: rgba(0,0,0,0.54);
}

.form-control-medium
{
    display: block;
    min-width: 150px;
    max-width: 151px;
}

.btn-dropdown {
	color: #495057;
	border-color:rgba(0,0,0,0.125);
}
.btn-dropdown-no-border {
	color: #495057;
	border:0px solid transparent;
}
.btn-toolbutton {
	color: #495057;		
	border-color:rgba(0,0,0,0.125);
	width: 38px;
	height: 38px;
	padding: 0;
}
.btn-toolbutton.active {
	color: white;
	background-color: #6c757d;
	border-color:rgba(0,0,0,0.125);
}
.feature-header
{
	font-size: 36px;
	font-weight: 200;
	color: #3bacf0;
	line-height: 1.2em !important;
	font-family: "Poppins",sans-serif;
	margin-bottom: 0;
	margin-top: 0;
}

.popover-content
{
	display: none;
}

.btn-lightblue
{
	color: #fff;
    background-color: #00b0f0;
    border-color: #00b0f0;
}

.btn-lightblue:hover {
    color: #fff;
    background-color: #00a1db;
    border-color: #00a1db;
}

.btn-lightblue:focus
{
	outline: none;
	box-shadow: none;
}